import axios from 'axios';

import { store } from '@app/config/store';
import { getToken, destroyToken } from '@app/components/auth/utils/tokenStorage';
import { actions } from '@app/components/auth/store/slice';

export interface ApiError {
  statusCode: number;
  message: string;
  error: string;
}

export interface ApiValidationError<T> {
  statusCode: number;
  message: string;
  errors: Record<keyof T, string>;
}

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

apiClient.interceptors.request.use(config => {
  const token = getToken();
  if (token && config?.headers) {
    config.headers['Authorization'] = `Bearer ${token.accessToken}`;
  }
  return config;
});

apiClient.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    if (response) {
      const { status } = response;
      if (status === 401) {
        destroyToken();
        store.dispatch(actions.clear());
      }
    }
    return Promise.reject(error);
  },
);
