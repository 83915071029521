import { ReactNode } from 'react';
import { createManager } from 'redux-injectors';

import { sliceKey, reducer } from '@app/components/auth/store/slice';

interface Props {
  children: ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const Provider = createManager({ name: sliceKey, key: sliceKey, reducer });

  return <Provider>{children}</Provider>;
};
