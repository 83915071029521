import { createTheme } from '@mui/material/styles';
import { createBreakpoints, createSpacing } from '@mui/system';
import createPalette from '@mui/material/styles/createPalette';
import shadows, { Shadows } from '@mui/material/styles/shadows';

import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
    black: Palette['primary'];
    blue: Palette['primary'];
    gold: Palette['primary'];
    gray: Palette['primary'];
  }
  interface PaletteOptions {
    white: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    gold: PaletteOptions['primary'];
    gray: PaletteOptions['primary'];
  }
}

const fontFamily = 'Montserrat, sans-serif';

enum Color {
  White = '#FFFFFF',
  Black = '#353535',
  Blue = '#789FFF',
  BlueLight = '#F2F6FF',
  Gold = '#F2C05A',
  Purple = '#AF7DD1',
  GreyBlueDark = '#85859A',
  GreyBlue = '#EBEFF8',
  GreyBlueLight = '#F8F9FF',
  Gray = '#D4D4EE',
}

const breakpoints = createBreakpoints({});
const spacing = createSpacing();
const palette = createPalette({
  background: {
    default: Color.White,
  },
  primary: {
    main: Color.Blue,
    light: Color.BlueLight,
  },
  secondary: {
    main: Color.Purple,
  },
  white: {
    main: Color.White,
  },
  text: {
    primary: Color.GreyBlueDark,
  },
  black: {
    main: Color.Black,
  },
  blue: {
    main: Color.GreyBlue,
    light: Color.GreyBlueLight,
    dark: Color.GreyBlueDark,
  },
  gold: {
    main: Color.Gold,
  },
  gray: {
    main: Color.Gray,
  },
});

export const theme = createTheme({
  breakpoints,
  spacing,
  palette,
  shadows: shadows.map(() => 'none') as Shadows,
  typography: {
    fontFamily,
    allVariants: {
      color: palette.black.main,
      lineHeight: 1.4,
    },
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
      fontSize: 16,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 36px rgba(16, 26, 46, 0.03)',
        },
        colorDefault: {
          backgroundColor: palette.white.main,
          color: palette.black.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          color: palette.text.primary,
          marginBottom: spacing(1 / 2),
        },
        shrink: {
          transform: 'unset',
          position: 'static',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: spacing(1 / 2),
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: spacing(3),
          marginBottom: spacing(3),
        },
        marginDense: {
          marginTop: 0,
          marginBottom: spacing(3),
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          fontSize: 14,
          minHeight: '1.3em',
          marginTop: 4,
          marginBottom: 'calc(-1.3em - 4px)',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: spacing(5),
          backgroundColor: palette.blue.light,
        },
        input: {
          borderRadius: spacing(5),
          padding: `${spacing(2)} ${spacing(3)}`,
        },
        notchedOutline: {
          borderRadius: spacing(5),
          borderColor: palette.blue.main,
          borderWidth: 1,
          borderStyle: 'solid',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -6,
        },
        label: {
          color: palette.text.primary,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 4,
          color: palette.text.primary,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: spacing(2),
        },
        select: {
          '&.MuiSelect-outlined': {
            paddingRight: spacing(5),
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
          borderColor: palette.blue.main,
          borderWidth: 1,
          borderStyle: 'solid',
          boxShadow: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `0 ${spacing(5)}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `${spacing(2.5)} ${spacing(5)} ${spacing(5)} ${spacing(5)}`,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 40,
          '&, &:hover': {
            boxShadow: 'unset',
          },
        },
        contained: {
          color: palette.white.main,
        },
        containedSizeLarge: {
          padding: `12px 25px`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
      },
    },
  },
});
