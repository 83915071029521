import { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthUser } from '@app/components/auth/hooks/useAuthUser';

interface Props {
  children: ReactElement;
  redirectTo?: string;
}

export const RequireAuth = ({ children, redirectTo = '/signin' }: Props) => {
  const location = useLocation();
  const { isAuthorized, isLoading } = useAuthUser();

  if (!isAuthorized && !isLoading) {
    return <Navigate replace to={redirectTo} state={{ from: location }} />;
  }

  return children;
};
