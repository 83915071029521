import { createStyles } from '@app/utils/mui';

export const styles = createStyles({
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
});
