import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import { actions } from '@app/components/auth/store/slice';
import { selectToken, selectUser } from '@app/components/auth/store/selectors';
import { Token } from '@app/components/auth/interfaces';
import { destroyToken, getToken, storeToken } from '@app/components/auth/utils/tokenStorage';
import { User } from '@app/components/user/interfaces';
import { ApiError } from '@app/config/axios';
import { QueryKey } from '@app/config/queryClient';
import { getUser } from '@app/components/auth/api';

interface AuthUser {
  isAuthorized: boolean;
  isLoading: boolean;
  setToken(token?: Token): void;
  logout: () => void;
  redirectToSignIn: () => void;
  user?: User;
  token?: Token;
}

export const useAuthUser = (): AuthUser => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const tokenFromStorage = getToken();
  const user = useSelector(selectUser);
  const isAuthorized = !!user;
  const queryCache = useQueryClient().getQueryCache();

  const setToken = useCallback(
    (newToken: Token) => {
      dispatch(actions.setToken(newToken));
      storeToken(newToken);
    },
    [dispatch],
  );
  const logout = useCallback<() => void>(() => {
    destroyToken();
    dispatch(actions.clear());
    queryCache.clear();
  }, [dispatch, queryCache]);
  const redirectToSignIn = useCallback<() => void>(
    () => navigate('/signin', { state: { from: location } }),
    [location, navigate],
  );

  const { isLoading, isInitialLoading } = useQuery<AxiosResponse<User>, AxiosError<ApiError>>(
    [QueryKey.AuthUser, token?.accessToken],
    getUser,
    {
      enabled: !!token && !user,
      suspense: false,
      onSuccess: ({ data }) => dispatch(actions.setUser(data)),
    },
  );

  useEffect(() => {
    if (!token) {
      const tokenFromStorage = getToken();
      if (tokenFromStorage) {
        dispatch(actions.setToken(tokenFromStorage));
      }
    }
  }, [token, dispatch]);

  return {
    isAuthorized,
    isLoading: tokenFromStorage ? isLoading : isInitialLoading,
    setToken,
    logout,
    redirectToSignIn,
    user,
    token,
  };
};
