import { Token } from '@app/components/auth/interfaces';

const key = 'auth';

export const storeToken = (token: Token): void => localStorage.setItem(key, JSON.stringify(token));
export const getToken = (): Token | null => {
  const token = localStorage.getItem(key);
  if (!token) return null;
  return JSON.parse(token);
};
export const destroyToken = (): void => localStorage.removeItem(key);
