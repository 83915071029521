import { AxiosPromise, AxiosRequestConfig } from 'axios';

import { apiClient } from '@app/config/axios';
import { User } from '@app/components/user/interfaces';
import {
  EmailVerifyResponse,
  EmailVerifyVariables,
  SignInVariables,
  SignUpVariables,
  Token,
} from '@app/components/auth/interfaces';

const endpoint = '/auth';

export const signIn = (variables: SignInVariables, config?: AxiosRequestConfig): AxiosPromise<Token> =>
  apiClient.post<Token>(`${endpoint}/login`, variables, config);

export const signUp = (variables: SignUpVariables, config?: AxiosRequestConfig): AxiosPromise<Token> =>
  apiClient.post<Token>(`${endpoint}/signup`, variables, config);

export const emailVerify = async (
  variables: EmailVerifyVariables,
  config?: AxiosRequestConfig,
): Promise<EmailVerifyResponse> => {
  const { data } = await apiClient.post<EmailVerifyResponse>(`${endpoint}/verify`, variables, config);
  return data;
};

export const resendVerificationCode = (
  variables: Pick<EmailVerifyVariables, 'email'>,
  config?: AxiosRequestConfig,
): Promise<void> => apiClient.post(`${endpoint}/resend-verification-code`, variables, config);

export const getUser = (config?: AxiosRequestConfig): AxiosPromise<User> =>
  apiClient.get<User>(`${endpoint}/user`, config);
