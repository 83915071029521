import ContentLoader from 'react-content-loader';

import { Box } from '@mui/material';

import { styles } from '@app/components/common/Loader/styles';

export const Loader = () => {
  return (
    <Box sx={styles.loaderContainer}>
      <ContentLoader viewBox="0 0 400 160" height={160} width={400} speed={2} backgroundColor="transparent">
        <circle cx="150" cy="86" r="8" />
        <circle cx="194" cy="86" r="8" />
        <circle cx="238" cy="86" r="8" />
      </ContentLoader>
    </Box>
  );
};
