import { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@app/utils/@reduxjs/toolkit';
import { AuthState, Token } from '@app/components/auth/interfaces';
import { User } from '@app/components/user/interfaces';

export const initialState: AuthState = {
  user: undefined,
  token: undefined,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    setToken(state, action: PayloadAction<Token>) {
      state.token = action.payload;
    },
    clear(state) {
      state.user = initialState.user;
      state.token = initialState.token;
    },
  },
});

export const { actions, reducer, name: sliceKey } = slice;
