import { QueryClient, MutationCache, QueryCache } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { t } from 'i18next';
import axios from 'axios';

import { translations } from '@app/locales/translations';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      if (axios.isAxiosError(error) && error.response?.status === 500) {
        toast.error(t(translations.errors.serverError));
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: error => {
      if (axios.isAxiosError(error) && error.response?.status === 500) {
        toast.error(t(translations.errors.serverError));
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: attempt => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      keepPreviousData: true,
      retry: 3,
      retryOnMount: true,
      suspense: true,
      useErrorBoundary: false,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
});

export enum QueryKey {
  AuthUser = 'authUser',
  Goal = 'goal',
  Incentive = 'incentive',
  GoalList = 'goalList',
  RewardList = 'rewardList',
  RecipientList = 'recipientList',
  ChallengeList = 'challengeList',
  CurrentChallenge = 'currentChallenge',
}
