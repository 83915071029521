import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GlobalStyle } from '@app/styles/global-styles';
import { translations } from '@app/locales/translations';
import { useAuthUser } from '@app/components/auth/hooks/useAuthUser';
import { Loader } from '@app/components/common/Loader';
import { RequireAuth } from '@app/components/auth/components/RequireAuth';
import { DashboardPage } from '@app/pages/DashboardPage/Loadable';
import { NotFoundPage } from '@app/pages/NotFoundPage/Loadable';
import { SignInPage } from '@app/pages/SignInPage/Loadable';
import { ChallengePage } from '@app/pages/ChallengePage/Loadable';

const LoadUser = () => {
  const { isLoading } = useAuthUser();

  if (isLoading) {
    return <Loader />;
  }
  return null;
};

export function App() {
  const { t, i18n } = useTranslation();

  return (
    <BrowserRouter>
      <LoadUser />
      <Helmet
        titleTemplate={`%s - ${t(translations.app.title)}`}
        defaultTitle={t(translations.app.title)}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content={t(translations.app.title)} />
      </Helmet>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <DashboardPage />
            </RequireAuth>
          }
        />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/challenge" element={<ChallengePage />} />
        <Route path="/challenge/:screen" element={<ChallengePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
